import React from "react";
import PropTypes from "prop-types";
import Placeholder from "../../assets/icons/smile@2x.png";

import "./Card.css";

const Card = ({ imgSrc, altText, onClick, banner, logo, templateType }) => {
  const handleClick = (e) => {
    onClick(e, imgSrc, logo, templateType, banner);
  };

  return (
    <a className="card" href="/template" onClick={handleClick}>
      <div className="placeholder-container">
        <img
          src={Placeholder}
          alt="placeholder to show where user uploaded file will appear"
          className="placeholder"
        />
      </div>

      <div className="card-template">
        <div className="logo-container">
          <img src={logo} alt="thoughtworks logo" className="logo" />
        </div>

        <div className="background-container">
          <img src={imgSrc} alt={altText} className="background" />
        </div>
      </div>
    </a>
  );
};

export default Card;

Card.propTypes = {
  imgSrc: PropTypes.string,
  altText: PropTypes.string,
  onClick: PropTypes.func,
  banner: PropTypes.string,
  logo: PropTypes.string,
  templateType: PropTypes.string,
};
