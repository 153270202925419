import React from "react";
import dragDrop from "../../assets/icons/drag-and-drop@2x.png";
import "./DropZone.css";
import PropTypes from "prop-types";

function DropZone({
  onclick,
  inputRef,
  onDrop,
  onEnter,
  onLeave,
  onOver,
  onChange,
}) {
  return (
    <div className="dropZone">
      <div
        className="dropBox"
        data-testid="dropBox"
        onDrop={onDrop}
        onDragOver={onOver}
        onDragEnter={onEnter}
        onDragLeave={onLeave}
      >
        <div className="drop-icon">
          <img src={dragDrop} alt="drag and drop" />
        </div>

        <p className="dropBoxText">
          Drag and drop your file or <br />
        </p>
        <input
          ref={inputRef}
          type="file"
          data-testid="input"
          onChange={onChange}
          style={{ display: "none" }}
          placeholder="Input"
          accept=".jpeg, .jpg, .png"
        />
        <button onClick={onclick} data-testid="button" className="button">
          Browse
        </button>
      </div>
    </div>
  );
}

DropZone.propTypes = {
  onclick: PropTypes.func,
  onDrop: PropTypes.func,
  inputRef: PropTypes.object,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onOver: PropTypes.func,
  onChange: PropTypes.func,
};

export default DropZone;
