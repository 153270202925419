/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
import PropTypes from "prop-types";

import "../Header/Header.css";

export default function CampaignSelector({ campaign, handleChange }) {
  return (
    <form>
      <label htmlFor="campaigns" className="link">
        Choose a campaign{" "}
      </label>
      <select
        name="campaigns"
        id="campaigns"
        className="chevron"
        defaultValue={campaign}
        onChange={handleChange}
      >
        {renderCampaignOptions()}
      </select>
    </form>
  );
}

const renderCampaignOptions = () => {
  const campaignOptions = [
    {
      id: "original",
      name: "Original",
      enabled: false,
    },
    {
      id: "impact",
      name: "Current Global",
      enabled: false,
    },
    {
      id: "thirtieth",
      name: "30th Anniversary",
      enabled: false,
    },
    {
      id: "dea",
      name: "Design. Engineering. AI.",
      enabled: true,
    },
  ];

  return (
    <>
      {campaignOptions
        .filter((option) => option.enabled)
        .map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
    </>
  );
};

CampaignSelector.propTypes = {
  campaign: PropTypes.string,
  handleChange: PropTypes.func,
};
