import horizontalShroomTop from "./assets/dea/horizontal/1_tw_DEA_horizontal_top.jpg";
import horizontalShroomBottom from "./assets/dea/horizontal/1_tw_DEA_horizontal_bottom.jpg";

import horizontalFluidTop from "./assets/dea/horizontal/2_tw_DEA_horizontal_top.jpg";
import horizontalFluidBottom from "./assets/dea/horizontal/2_tw_DEA_horizontal_bottom.jpg";

import horizontalSkyTop from "./assets/dea/horizontal/3_tw_DEA_horizontal_top.jpg";
import horizontalSkyBottom from "./assets/dea/horizontal/3_tw_DEA_horizontal_bottom.jpg";

import horizontalMountainTop from "./assets/dea/horizontal/4_tw_DEA_horizontal_top.jpg";
import horizontalMountainBottom from "./assets/dea/horizontal/4_tw_DEA_horizontal_bottom.jpg";

import squareTopPeacock from "./assets/dea/square/1_tw_DEA_square_top.jpg";
import squareBottomPeacock from "./assets/dea/square/1_tw_DEA_square_bottom.jpg";

import squareTopFish from "./assets/dea/square/2_tw_DEA_square_top.jpg";
import squareBottomFish from "./assets/dea/square/2_tw_DEA_square_bottom.jpg";

import squareTopSky from "./assets/dea/square/3_tw_DEA_square_top.jpg";
import squareBottomSky from "./assets/dea/square/3_tw_DEA_square_bottom.jpg";

import squareTopColor from "./assets/dea/square/4_tw_DEA_square_top.jpg";
import squareBottomColor from "./assets/dea/square/4_tw_DEA_square_bottom.jpg";

const images = {
  illustration1: horizontalShroomBottom,
  illustration2: horizontalFluidBottom,
  illustration3: horizontalSkyBottom,
  illustration4: horizontalMountainBottom,
  fbLogo1: horizontalShroomTop,
  fbLogo2: horizontalFluidTop,
  fbLogo3: horizontalSkyTop,
  fbLogo4: horizontalMountainTop,
  igIllustration1: squareTopPeacock,
  igLogo1: squareBottomPeacock,
  igIllustration2: squareTopFish,
  igLogo2: squareBottomFish,
  igIllustration3: squareTopSky,
  igLogo3: squareBottomSky,
  igIllustration4: squareTopColor,
  igLogo4: squareBottomColor,
  dea: true,
};

export default images;
