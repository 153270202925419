import React, { useState, useEffect } from "react";
import { withRouting, withAuth } from "./utils";
import SocialAmplificationTool from "./containers/SocialAmplificationTool";
import { flow } from "lodash-es";

import originalImages from "./originalAssets";
import impactImages from "./impactAssets";
import thirtiethImages from "./thirtiethAssets";
import deaImages from "./deaAssets";
import { ImagesProvider } from "./ImagesContext";

import "./App.css";

const App = () => {
  const [campaign, setCampaign] = useState("dea");
  const [images, setImages] = useState(deaImages);

  useEffect(() => {
    switch (campaign) {
      case "original":
        setImages(originalImages);
        break;
      case "impact":
        setImages(impactImages);
        break;
      case "thirtieth":
        setImages(thirtiethImages);
        break;
      case "dea":
        setImages(deaImages);
        break;
      default: {
        setImages(originalImages);
      }
    }
  }, [campaign]);

  return (
    <ImagesProvider value={images}>
      <SocialAmplificationTool
        campaign={campaign}
        campaignHandler={setCampaign}
      />
    </ImagesProvider>
  );
};

export default flow(withAuth, withRouting)(App);
