import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./Panel.css";
import PropTypes from "prop-types";
import linkedIn from "../../assets/social-logos/linkedin@2x.png";
import facebook from "../../assets/social-logos/facebook@2x.png";
import twitter from "../../assets/social-logos/x@2x.png";
import instagram from "../../assets/social-logos/instagram@2x.png";
import BackNav from "./BackNav";
import ImagesContext from "../../ImagesContext";

export default function Panel({
  text,
  shareable,
  imageToShare,
  setShareable,
  location,
  setCrop,
  setTemplateData,
  templateData,
}) {
  const [downloaded, setDownloaded] = useState(false);
  let history = useHistory();
  const images = useContext(ImagesContext);
  const captions = [
    "Proud to be part of Thoughtworks' growth and commitment to delivering #ExtraordinaryImpact.",
  ];

  const handleBackNavClick = (e) => {
    e.preventDefault();
    switch (location) {
      case "/template":
        history.push("/");
        break;
      case "/crop":
        if (templateData.type === "main") {
          setTemplateData({ file: null });
          setCrop({
            width: 227,
            aspect: 0.89 / 1,
          });
          history.push("/template");
        } else {
          setTemplateData({ file: null });
          setCrop({
            width: 270,
            aspect: 1 / 1,
          });
          history.push("/template");
        }
        break;
      case "/canvas":
        if (shareable === true) {
          history.push("/template");
        } else {
          setDownloaded(false);
          setShareable(true);
        }
        break;
      default: {
        history.push("/");
      }
    }
  };

  const handleClick = (e) => {
    setDownloaded(true);
    setShareable(false);
  };

  return (
    <div className="panel">
      <div className="aligned">
        <div className="text">
          {location !== "/" && <BackNav onClick={handleBackNavClick} />}
          {location === "/" && <div className="back-nav-clear"></div>}
          {text}
        </div>
        {downloaded && (
          <div className="downloaded">
            <div className="social-icons-container">
              <a
                href="https://instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="instagram logo" />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedIn} alt="linked in logo" />
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="facebook logo" />
              </a>
              <a
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="twitter logo" />
              </a>
            </div>
            <div className="download-info">
              <p>
                Click on an icon to open the social media platform you wish to
                share on. You can upload your image and write a brief post about
                how you or your team puts in the extra needed to overcome the
                ordinary at Thoughtworks.
              </p>

              {(images.original || images.thirtieth || images.dea) && (
                <h3>Suggested caption: </h3>
              )}
              {images.impact && <h3>Suggested hashtags: </h3>}
              <div className="captions">
                {images.original &&
                  captions.map((caption) => {
                    return <p key={caption}>{caption}</p>;
                  })}
                {images.impact && (
                  <p>
                    #ExtraordinaryImpact <br />
                    #ExtraOvercomesOrdinary
                    <br />
                    #Thoughtworks
                  </p>
                )}
                {images.thirtieth && (
                  <p>
                    It’s another milestone day at Thoughtworks! Proud to be a
                    part of this global community that has been delivering
                    technology excellence for 30 years #ThoughtworksTurns30
                    <br />
                    https://thght.works/3ADaNI6
                  </p>
                )}
                {images.dea && (
                  <>
                    <p>
                      Excited to share @Thoughtworks new brand positioning -
                      Design. Engineering. AI. These pillars showcase our
                      dedication to design-led innovation, engineering
                      excellence and AI-first software delivery.
                      #ExtraordinaryImpact
                    </p>
                    <p>
                      <b>Or - longer post:</b>
                    </p>
                    <p>
                      I’m excited to share that @Thoughtworks has unveiled our
                      new brand positioning focused on Design, Engineering and
                      AI. It’s more than a brand update, the pillars reflect the
                      heart of what we do: pushing boundaries in design-led
                      innovation, engineering excellence and AI-first software
                      delivery. Let’s go! #ExtraordinaryImpact
                    </p>
                  </>
                )}
              </div>
              <div>
                <p>
                  To maintain good accessibilty standards, please take the time
                  to add 
                  <a
                    href="https://supercooldesign.co.uk/blog/how-to-write-good-alt-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    alt text 
                  </a>
                  to your image.{" "}
                </p>
                <h3>Suggested Alt text: </h3>
                {images.thirtieth ? (
                  <p className="captions">
                    My photo alongside a celebratory graphic for Thoughtworks 30
                    year anniversary
                  </p>
                ) : (
                  <p className="captions">
                    A photo of me alongside a whimsical illustration and a
                    headline that reads where extra impact overcomes ordinary
                    followed by the Thoughtworks logo.
                  </p>
                )}
                <>
                  <p className="close-up">find out how:</p>
                  <a
                    href="https://help.instagram.com/503708446705527"
                    className="ig-alt-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.facebook.com/help/214124458607871"
                    target="_blank"
                    className="fb-alt-text"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                  <a
                    href="https://www.linkedin.com/help/linkedin/answer/109799/adding-alternative-text-to-images-for-accessibility?lang=en"
                    className="fb-alt-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                  <a
                    href="https://help.twitter.com/en/using-twitter/picture-descriptions"
                    className="fb-alt-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &#x1D54F;
                  </a>
                </>
              </div>
            </div>
          </div>
        )}
        {shareable && (
          <a
            href={imageToShare}
            download="thoughtworks-banner.png"
            onClick={handleClick}
          >
            <div className="btn">Download image</div>
          </a>
        )}
      </div>
    </div>
  );
}
Panel.propTypes = {
  text: PropTypes.object,
  shareable: PropTypes.bool,
  imageToShare: PropTypes.string,
  setShareable: PropTypes.func,
  location: PropTypes.string,
  setTemplateData: PropTypes.func,
  setCrop: PropTypes.func,
  templateData: PropTypes.object,
};
