/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
import PropTypes from "prop-types";
import logo from "../../assets/tw-logos/thoughtworks_flamingo_wave.png";
import CampaignSelector from "../CampaignSelector/CampaignSelector";

import "./Header.css";

export default function Header({ setCampaign, campaign, location }) {
  function handleChange(event) {
    setCampaign(event.target.value);
  }

  return (
    <div className="header" data-testid="header">
      <a href="/">
        <img className="logo" src={logo} alt="logo" />
      </a>
      {location === "/" && (
        <div className="link-container">
          <CampaignSelector campaign={campaign} handleChange={handleChange} />
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  setCampaign: PropTypes.func,
  campaign: PropTypes.string,
  location: PropTypes.string,
};
