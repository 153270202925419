import React from "react";
import PropTypes from "prop-types";
import Placeholder from "../../assets/icons/smile@2x.png";

import "./IgCard.css";

const IgCard = ({ imgSrc, altText, onClick, banner, logo, templateType }) => {
  const handleClick = (e) => {
    onClick(e, imgSrc, logo, templateType, banner);
  };

  return (
    <a className="ig-card" href="/template" onClick={handleClick}>
      <div className="card-template">
        <div className="background-container">
          <img src={imgSrc} alt={altText} className="background" />
        </div>
        <div className="placeholder-container">
          <img
            src={Placeholder}
            alt="placeholder to show where user uploaded file will appear"
            className="placeholder"
          />
        </div>
      </div>
      <div className="logo-container">
        <img src={logo} alt="thoughtworks logo" className="logo" />
      </div>
    </a>
  );
};

export default IgCard;

IgCard.propTypes = {
  imgSrc: PropTypes.string,
  altText: PropTypes.string,
  onClick: PropTypes.func,
  banner: PropTypes.string,
  logo: PropTypes.string,
  templateType: PropTypes.string,
};
