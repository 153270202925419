import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ImagesContext from "../../ImagesContext";
import Card from "../Card/Card";
import IgCard from "../Card/IgCard";
import "./Selector.css";

export default function Selector({ setLocation, setTemplateData }) {
  const images = useContext(ImagesContext);
  let history = useHistory();
  let location = useLocation();
  useEffect(() => {
    setLocation(location.pathname);
  });

  const igCardOptions = [
    {
      banner: "illustration1",
      src: images.igIllustration1,
      altText:
        "background option 1: a patterned background in thoughtworks brand colors",
      logo: images.igLogo1,
    },
    {
      banner: "illustration2",
      src: images.igIllustration2,
      altText:
        "background option 2: a patterned background in thoughtworks brand colors",
      logo: images.igLogo2,
    },
    {
      banner: "illustration3",
      src: images.igIllustration3,
      altText:
        "background option 3: a patterned background in thoughtworks brand colors",
      logo: images.igLogo3,
    },
    {
      banner: "illustration4",
      src: images.igIllustration4,
      altText:
        "background option 4: a patterned background in thoughtworks brand colors",
      logo: images.igLogo4,
    },
  ];
  const cardOptions = [
    {
      banner: "illustration1",
      src: images.illustration1,
      altText:
        "background option 1: a patterned background in thoughtworks brand colors",
      logo: images.fbLogo1,
    },
    {
      banner: "illustration2",
      src: images.illustration2,
      altText:
        "background option 2: a patterned background in thoughtworks brand colors",
      logo: images.fbLogo2,
    },
    {
      banner: "illustration3",
      src: images.illustration3,
      altText:
        "background option 3: a patterned background in thoughtworks brand colors",
      logo: images.fbLogo3,
    },
    {
      banner: "illustration4",
      src: images.illustration4,
      altText:
        "background option 4:a patterned background in thoughtworks brand colors",
      logo: images.fbLogo4,
    },
  ];

  const loadTemplate = (e, imgSrc, logo, templateType, banner) => {
    e.preventDefault();
    setTemplateData({
      banner: banner,
      logo: logo,
      type: templateType,
      imgSrc: imgSrc,
    });
    history.push("/template");
  };

  return (
    <div className="main-area">
      <div className="selector">
        <div className="title">
          <h1>Choose your template:</h1>
        </div>
        <h2>Horizontal</h2>
        <div className="card-container">
          {cardOptions.map((option) => {
            return (
              <Card
                templateType="main"
                imgSrc={option.src}
                altText={option.altText}
                onClick={loadTemplate}
                banner={option.banner}
                logo={option.logo}
                key={option.banner}
              />
            );
          })}
        </div>
        <h2>Square</h2>

        <div className="card-container ig-1">
          {igCardOptions.map((option) => {
            return (
              <IgCard
                templateType="instagram"
                imgSrc={option.src}
                altText={option.altText}
                onClick={loadTemplate}
                banner={option.banner}
                logo={option.logo}
                key={option.banner}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

Selector.propTypes = {
  setLocation: PropTypes.func,
  setTemplateData: PropTypes.func,
};
